






































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import Note from '@/app/ui/components/Note/Note.vue'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import controller, {
  VoucherConfigSKUParamsInterface,
} from '@/app/ui/controllers/VoucherConfigSKUController'
import {Utils} from '@/app/infrastructures/misc/Utils'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalUploadBulkyVoucherSKU from "@/app/ui/views/Voucher/components/ModalUploadBulky/UploadBulkyVoucherSKU.vue"
import {EnumStatusUpload} from '@/app/infrastructures/misc/Constants/upload'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  EnumFilterStatusPreviewUploadBulky,
  EventBusConstants,
} from '@/app/infrastructures/misc'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_modal.vue'
import {Dictionary} from 'vue-router/types/router'
import CardUploadResult from '@/app/ui/components/Card/CardUploadResult.vue'
import {UploadBulkyVoucherSKU} from '@/domain/entities/VoucherConfigSKU'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {DataObject, HeaderObject} from '@/app/ui/components/DataTableV2/type'

interface NoteInterface {
  type: 'normal' | 'failed' | 'success'
  text: string
  isVisible: boolean
}

interface statusOptionInterface {
  label: string
  value: EnumFilterStatusPreviewUploadBulky
}

interface parametersInterface {
  page: number
  perPage: number
  status: statusOptionInterface
}

interface perPageOptionInterface {
  label: string
  value: number
}


interface modalInfoInterface {
  visible: boolean
  title: string
  dataFailed: Array<string | number>
  textButton: string
}

@Component({
  components: {
    DataTableV2,
    Button,
    BackIcon,
    Note,
    LoadingOverlay,
    ModalUploadBulkyVoucherSKU,
    DropdownSelect,
    PaginationNav,
    ModalAction,
    Modal,
    SuccessIcon,
    CardUploadResult,
  },
})
export default class PreviewUploadBulk extends Vue {
  note: NoteInterface = {
    type: 'normal',
    text: '',
    isVisible: false,
  }
  report = {
    fileName: '-',
    totalRowInvalid: '-',
    totalRowValid: '-',
    totalValidAmountNegative: '-',
    totalValidAmountPositive: '-',
  }
  showUploadModal = false
  statusOptions: Array<statusOptionInterface> = [
    {
      label: 'Semua',
      value: EnumFilterStatusPreviewUploadBulky.ALL,
    },
    {
      label: 'Valid',
      value: EnumFilterStatusPreviewUploadBulky.VALID,
    },
    {
      label: 'Invalid',
      value: EnumFilterStatusPreviewUploadBulky.INVALID,
    },
  ]
  parameters: parametersInterface = {
    page: 1,
    perPage: 10,
    status: this.statusOptions[0],
  }
  controller = controller
  perPageOptions: Array<perPageOptionInterface> = [
    {
      label: '50',
      value: 50,
    },
  ]
  modalInfo: modalInfoInterface = {
    visible: false,
    title: '',
    dataFailed: [0, ''],
    textButton: '',
  }
  isModalSuccessVisible = false
  headers: HeaderObject[] = [
    {
      title: 'SKU',
      width: '40px',
    },
    {
      title: 'Voucher Name',
      width: '100px',
    },
    {
      title: 'Amount',
    },
    {
      title: 'Status',
    },
  ]
  dataItems: DataObject[][] = []

  mounted(): void {
    this.onSetDisplayBreadcrumb(false)
  }

  private handleBack(): void {
    this.$router
      .push({name: 'VoucherConfigMainPage'})
      .catch(() => false)
  }

  private actionModalUpload(): void {
    this.showUploadModal = true
  }

  get isDisabledButtonSendData(): boolean {
    return (
      Number(this.report.totalRowInvalid) !== 0 ||
      Number(this.report.totalRowValid) === 0
    )
  }

  private handleSendData(): void {
    this.note = {
      type: 'success',
      text: ``,
      isVisible: false,
    }

    if (!this.isDisabledButtonSendData) {
      this.modalInfo = {
        visible: true,
        title: 'Kirim Data',
        dataFailed: [0, ''],
        textButton: 'Kirim',
      }
    }
  }

  private closeModalSuccess(): void {
    this.controller.setStatusUpload(EnumStatusUpload.START)
    this.isModalSuccessVisible = false
    this.handleBack()
  }

  @Watch('controller.responseUploadBulky', {immediate: true, deep: true})
  onResponseUploadBulky(val: UploadBulkyVoucherSKU | null): void {
    if (!val) return
    let countRowInvalid = 0
    let countRowValid = 0
    this.dataItems = []
    this.note = {
      type: 'success',
      text: ``,
      isVisible: false,
    }
    val.data.forEach((item) => {
      if (item.status.toLowerCase() !== 'valid') {
        countRowInvalid++
      } else {
        countRowValid++
      }

      this.dataItems.push([
        {
          value: item.sku,
          width: '40px',
        },
        {
          value: item.name,
          width: '100px',
        },
        {
          value: Utils.toRupiah(item.amount),
        },
        {
          value: item.status,
        },
      ])
    })
    this.report = {
      fileName: val.result.fileName,
      totalRowInvalid: String(countRowInvalid),
      totalRowValid: String(countRowValid),
      totalValidAmountPositive: String(countRowValid),
      totalValidAmountNegative: String(countRowInvalid),
    }

    this.note = {
      type: 'success',
      text: `All data is correct. Please click Send Data button to submit data`,
      isVisible: true,
    }

    if (countRowInvalid > 0) {
      this.note = {
        type: 'failed',
        text: `${countRowInvalid} rows invalid. Please fixing your data and reupload`,
        isVisible: true,
      }
    }
    this.showUploadModal = false
    controller.setStatusUpload(EnumStatusUpload.START)
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  get params(): VoucherConfigSKUParamsInterface {
    return {
      ...this.parameters,
    }
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router
      .replace({
        query: {...val},
      })
      .catch(() => false)
  }

  private fetchPreviewUploadBulky(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    if (!controller.fileUploaded) {
      return
    }

    this.controller.getPreviewUploadBulky({
      file: controller.fileUploaded,
    })
  }

  private resetModalInfo(): void {
    this.modalInfo = {
      visible: false,
      title: '',
      dataFailed: [0, ''],
      textButton: '',
    }
  }

  private handleAction(): void {
    if (controller.fileUploaded) {
      controller.uploadBulky({
        file: controller.fileUploaded,
      })
    }
  }

  @Watch('controller.statusUploadBulkyDone')
  onStatusUploadBulkyCompleted(isDone: boolean): void {
    if (isDone) {
      this.isModalSuccessVisible = true
      this.resetModalInfo()
    }
  }

  private closeUploadModal(refetch?: boolean): void {
    this.showUploadModal = false
    if (refetch === true) {
      this.fetchPreviewUploadBulky(true)
    }
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    this.controller.setStatusUpload(EnumStatusUpload.START)
    this.controller.setDatRetry(NaN)
    this.controller.setUploadFile(null)
  }
}
